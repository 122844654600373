export function enrichQuery({
  request,
  options,
}: {
  request: any;
  options: any;
}) {
  // API needs `de` for `site` param, even for `de_en` (language is determined by `lang` param which is `de_en`)
  let site = globalThis.lang;
  if (request.includes(useRuntimeConfig().public.apiUrl)) {
    site = globalThis.lang === "de_en" ? "de" : globalThis.lang;
  }
  options.query = {
    ...options.query,
    token: globalThis.previewToken,
    site,
  };
}
