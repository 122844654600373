import { ofetch } from "ofetch";
import { useAuthStore } from "~/store/AuthStore";
import { enrichQuery } from "#imports";

export default defineNuxtPlugin(() => {
  // this is only used in browser - on server requests "node-fetch" is used which is why we
  // needed to add enrichQuery also in `use-fetch-api`
  globalThis.$fetch = ofetch.create({
    onRequest: ({ request, options }) => {
      enrichQuery({ request, options });
    },
    onResponseError: (error) => {
      if (error.response.status === 401) {
        const { clear } = useAuthStore();
        clear();
      }
    },
  });
});
