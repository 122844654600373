export default function serviceDetailPath(slug: string) {
  if (!slug) {
    throw new Error("slug must be defined");
  }

  function getBasePath() {
    if (
      globalThis.lang === "da" ||
      globalThis.lang === "en" ||
      globalThis.lang === "de" ||
      globalThis.lang === "de_en"
    ) {
      return `/${globalThis.lang}/services`;
    }
    throw new Error(
      "globalThis.lang holds unknown language: " + globalThis.lang,
    );
  }

  return `${getBasePath()}/${slug}`;
}
