<template>
  <BaseContent>
    <h2 class="typo-h2 mb-6 text-grey-900">
      {{
        $t("service_page.reviews_of_service_x", {
          service: raw?.apiService?.name,
        })
      }}
    </h2>
    <div class="grid grid-cols-1 gap-4 text-center lg:grid-cols-2">
      <div
        v-for="(review, index) in content?.service_reviews"
        :key="index"
        class="grid min-h-[360px] grid-cols-2 overflow-hidden rounded-lg text-left"
      >
        <div
          class="flex flex-col p-4"
          :style="`background-color:${backgroundColor}`"
        >
          <div class="mb-5 flex items-center gap-2">
            <CmsImage
              v-if="review.author_image?.url"
              :max-width="60"
              :src="review.author_image.url"
              class="h-12 w-12 rounded-full"
            />
            <div
              v-else
              class="flex h-12 w-12 items-center justify-center rounded-full bg-grey-100 text-semantic-info-600"
            >
              {{ initials(review.name) }}
            </div>
            <div class="typo-body-lg font-semibold text-grey-900">
              {{ review.name }}
            </div>
          </div>
          <RatingStars :rating="review.rating" class="mb-3" />
          <div class="typo-body-xl mb-5 flex-1 text-grey-900">
            {{ review.text }}
          </div>
          <div>
            {{ $t("service_page.delivered_by") }}
            <SafeNuxtLink
              class="inline underline"
              :to="{ name: vendorLinkName(review.vendor?.slug) }"
              >{{ review.vendor?.title }}</SafeNuxtLink
            >
          </div>
        </div>
        <CmsImage
          class="h-full object-cover object-center"
          :max-width="600"
          :src="review?.image?.url"
        />
      </div>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import { initials } from "~/utils/initials";
import { CATEGORIES } from "~/constants/categories-and-services";
const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  raw: {
    type: Object,
    required: true,
  },
});

const backgroundColor = computed(() => {
  return (
    CATEGORIES[props.raw?.apiService?.category?.plain_text_id]?.colors.light ||
    "#fff"
  );
});
</script>
