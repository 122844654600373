<template>
  <BasePopover
    no-padding
    hide-on-click-inside
    content-max-height="250px"
    :content-width="contentWidth"
    v-bind="popover ? popover : {}"
    offset-y="8px"
    @show="onShow"
  >
    <template #trigger>
      <div
        :class="triggerElementClasses"
        class="typo-body flex h-full select-none items-center justify-between text-ellipsis rounded-[6px] border border-grey-300 px-2 py-1 text-body text-grey-900 transition-all hover:bg-grey-50"
      >
        <span
          class="truncate whitespace-nowrap"
          :class="{ 'text-grey-500': !modelValue }"
          :title="currentlySelectedLabel()"
        >
          <span v-if="selectedPrefix" class="text-grey-500"
            >{{ selectedPrefix }}
          </span>

          {{ currentlySelectedLabel() }}
        </span>
        <div class="ml-1 flex items-center gap-2">
          <BaseIcon
            v-if="modelValue && showDeselect"
            icon="close"
            :size="12"
            class="cursor-pointer rounded-full bg-grey-100 p-1 text-grey-500 transition-all"
            @click.stop="clear"
          ></BaseIcon>

          <BaseIcon
            icon="caret-down"
            :size="12"
            class="text-grey-500 transition-all"
          />
        </div>
      </div>
    </template>

    <template #content>
      <div
        class="typo-body flex max-w-[100vw] flex-col gap-1 overflow-auto rounded-lg bg-white p-2 text-grey-900"
      >
        <div v-if="showSearch" class="relative mb-3">
          <input
            ref="searchInput"
            v-model="search"
            type="text"
            :placeholder="$t('components.search.search')"
            class="w-full rounded-md border border-grey-200 p-2 pl-8 outline-none focus:border-grey-300"
            @click.stop
          />
          <BaseIcon
            icon="search"
            :size="14"
            class="pointer-events-none absolute left-3 top-0 flex h-full items-center text-grey-500"
          />
        </div>
        <div
          v-for="(option, index) in filteredOptions"
          :key="index"
          class="typo-body mb-1 flex w-full cursor-pointer items-center justify-between rounded-lg p-2 text-grey-900 hover:bg-grey-100"
          :class="{
            'bg-grey-100 font-medium': optionIsSelected(option.value),
          }"
          @click="toggleOption(option.value)"
        >
          <div :class="{ 'ml-4': option.isChild }" class="whitespace-nowrap">
            {{ option.label }}
          </div>
          <div class="ml-2 flex w-[14px] items-center">
            <BaseIcon
              v-if="optionIsSelected(option.value)"
              icon="checkmark"
              class="text-grey-900"
              :size="14"
            />
          </div>
        </div>
      </div>
    </template>
  </BasePopover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BasePopoverProps } from "~/types/component-props";
const { t } = useI18n();

interface Props {
  options: { label: string; value: string | number; isChild?: boolean }[];
  modelValue?: string | number | null;
  placeholder?: string;
  allowDeselect?: boolean;
  selectedTranslationKey?: string;
  popover?: BasePopoverProps;
  selectedPrefix?: string;
  triggerElementClasses?: string;
  showSearch?: boolean;
  showDeselect?: boolean;
  contentWidth?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: "",
  allowDeselect: false,
  selectedTranslationKey: undefined,
  isChild: false,
  popover: () => ({}),
  selectedPrefix: undefined,
  triggerElementClasses: "",
  showDeselect: false,
  contentWidth: "auto",
  modelValue: "",
});

const emit = defineEmits(["update:modelValue", "clear"]);

const valueModel = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  },
});

function currentlySelectedLabel(): string {
  const option = props.options.find(
    (option) => option.value === valueModel.value,
  );
  if (option) {
    return props.selectedTranslationKey
      ? t(props.selectedTranslationKey, { label: option.label })
      : option.label;
  }
  return props.placeholder;
}

function optionIsSelected(value: string | number) {
  return valueModel.value === value;
}

function toggleOption(value: string | number) {
  if (props.allowDeselect && optionIsSelected(value)) {
    valueModel.value = null;
    return;
  }
  valueModel.value = value;
}

function clear() {
  emit("clear");
}

async function onShow() {
  await nextTick();
  if (props.showSearch && searchInput.value) {
    searchInput.value.focus();
  }
}

const searchInput = ref();
const search = ref("");

const filteredOptions = computed(() => {
  return props.options.filter((option) =>
    option.label.toLowerCase().includes(search.value.toLowerCase()),
  );
});
</script>
