import { Router } from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
  if (!process.server) return;

  const res = nuxtApp.ssrContext?.event?.node?.res;
  const req = nuxtApp.ssrContext?.event?.node?.req;

  if (!(res && req && req.url)) return;

  // only consider redirecting when it's a vendor page, vendor product list or vendor product detail
  if (
    !/^\/(da|en|de|de_en)\/(partnere|vendors|partner)\/[a-z-]+(\/produkter|\/products|\/produkte)?(\/[a-z0-9-]+)?$/.test(
      req.url as string,
    )
  )
    return;

  const routes = (nuxtApp.$router as Router).getRoutes();
  const paths = routes.map((route) => route.path);

  // do nothing when we have a route
  if (paths.includes(req.url)) return;

  const searchRoute = routes.find((r) => r.name === "discovery vendors");

  res.writeHead(307, { Location: `${searchRoute?.path}` });
  res.end();
});
