function getService(id: string) {
  return useFetchApi(`public/services/${id}`, {
    params: {
      resource: "full",
    },
  });
}
function getServices(options: any) {
  return useFetchApi("public/services", options);
}

function getServiceCategories(options: any) {
  return useFetchApi("public/services/categories", options);
}

function getServicePartners(id: string) {
  return useFetchApi(`/public/services/${id}/partners`, {
    query: {
      resource: "full",
    },
  });
}

export { getServiceCategories, getServices, getService, getServicePartners };
