<template>
  <div>
    <CartConflictModal
      :value="cartConflictModalShowing"
      @confirm="conflictConfirm"
      @cancel="conflictCancel"
    />
    <h2
      class="typo-h5 mb-6 font-bold text-grey-900"
      :class="additionalHeaderClasses"
    >
      {{ $t("vendor_page.products") }}
    </h2>
    <div
      :class="{ 'grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4': grid }"
    >
      <SafeNuxtLink
        v-for="(product, index) in products"
        :key="product.id"
        class="relative col-span-1 flex justify-between gap-4 border-b border-grey-200 pb-1 pt-4 first:pt-0 last:border-b-0"
        :class="{ 'flex-col-reverse !gap-2 !border-none !pt-0': grid }"
        :to="{
          name: productLinkName(product.slug),
        }"
      >
        <div>
          <p
            class="typo-body mb-2 font-semibold text-grey-900"
            :class="{ '!mb-1': grid }"
          >
            <span
              v-if="currentProductCount(product.id)"
              class="typo-caption mr-1 inline-flex h-[18px] w-[18px] items-center justify-center rounded-full bg-brand-600 text-white"
              >{{ currentProductCount(product.id) }}</span
            >{{ product.name }}
          </p>
          <p
            class="typo-body text-grey-700"
            :class="{ '!text-grey-500': grid }"
          >
            {{ formatCurrency(product.price) }}
          </p>
        </div>

        <div
          class="mb-1 h-[80px] w-[80px] rounded-lg bg-grey-100"
          :class="{
            'h-full w-full': grid,
          }"
        >
          <!-- done -->
          <CmsImage
            v-if="product.media?.url"
            :src="product.media?.url"
            :alt="product.name"
            :max-width="288"
            :width="grid ? 3 : 80"
            :height="grid ? 2 : 80"
            :class="{
              'aspect-3/2 !h-auto w-full object-cover object-center': grid,
            }"
            class="h-[80px] w-[80px] rounded-lg object-cover object-center"
            :loading="index < 8 ? 'eager' : 'lazy'"
            :fetch-priority="index < 8 ? 'high' : 'auto'"
          />
          <div
            v-else-if="grid"
            class="flex aspect-3/2 items-center justify-center rounded-lg bg-grey-100"
          >
            <BaseIcon icon="picture" class="text-grey-300" />
          </div>
        </div>

        <div
          class="absolute bottom-3 right-1 flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-lg border border-grey-200 bg-white p-4 shadow-alternative-glow transition-all hover:bg-grey-50"
          :class="{ 'right-2 top-2': grid }"
          @click.prevent="addProduct(product)"
        >
          <!-- hard coded color text-brand-600 -> #601EFA because of a CSS overwrite issue -->
          <BaseIcon icon="plus" color="#601EFA" :size="12" />
        </div>
      </SafeNuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { toast } from "~/utils/toast";
import { productLinkName } from "~/utils/linking";
import { useCartStore } from "~/store/CartStore";
import CartConflictModal from "~/pages/components/cart/cart-conflict-modal.vue";
import { track } from "~/utils/tracking/tracking";
import { useCurrency } from "#imports";

const { t } = useI18n();
const cartStore = useCartStore();
const { formatCurrency } = useCurrency();

interface Props {
  products: OgApi.Webshop.Product[];
  vendor: OgApi.Vendor;
  additionalHeaderClasses?: string;
  grid?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  additionalHeaderClasses: "",
});

function currentProductCount(id: string) {
  return cartStore.productCountMap[id] || 0;
}

const cartConflictModalShowing = ref(false);
const productAwaitingConfirmation = ref<OgApi.Webshop.Product | null>(null);

async function addProduct(product: OgApi.Webshop.Product) {
  if (
    cartStore.cart?.partner &&
    cartStore.cart?.partner?.id !== props.vendor.id
  ) {
    cartConflictModalShowing.value = true;
    productAwaitingConfirmation.value = product;
    return;
  }
  await cartStore.addProductToCart(product.id, 1);
  track({
    event: "add_to_cart",
    metadata: {
      type: "product",
      products: [
        {
          name: product.name,
          product_id: product.id,
          price: product.price,
          quantity: 1,
        },
      ],
    },
    posthogEvent: {
      name: "Product added to cart",
      properties: {
        url: window.location.href,
      },
    },
  });
  toast({
    text: t("product_page.added_x_to_cart", { name: product.name }),
    duration: 3000,
    link: {
      label: t("cart.go_to_cart"),
      to: { name: "cart" },
    },
  });
}

async function conflictConfirm() {
  cartConflictModalShowing.value = false;
  await cartStore.deleteCart();
  if (productAwaitingConfirmation.value) {
    await addProduct(productAwaitingConfirmation.value);
    productAwaitingConfirmation.value = null;
  }
}

function conflictCancel() {
  cartConflictModalShowing.value = false;
}
</script>
