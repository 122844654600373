<template>
  <BaseContent :style="sectionBackgroundColor">
    <ViewAllHeader
      :header-text="headerText"
      :header-link="headerLink"
      :header-link-text="headerLinkText"
    />
    <div class="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-5">
      <NuxtLink
        v-for="service in props.content.services"
        :key="service.id"
        class="flex flex-col justify-between gap-6 rounded-lg p-5 pt-4"
        :to="service.url"
        :style="serviceBackgroundColor(service)"
      >
        <div class="flex flex-col gap-2">
          <h3 class="typo-body flex items-center justify-between gap-2">
            <span class="truncate text-grey-900">{{ service.title }}</span
            ><span class="-mb-1 text-grey-500"
              ><BaseIcon icon="arrow-right" :size="16"
            /></span>
          </h3>
          <p
            class="typo-h5 line-clamp-3 text-grey-900 [overflow-wrap:anywhere]"
          >
            {{ service.excerpt }}
          </p>
        </div>
        <div class="flex justify-center">
          <CmsImage
            v-if="getServiceIllustration(service)"
            :src="getServiceIllustration(service)"
            alt="Service illustration"
            :width="190"
            :height="190"
            :max-width="190"
            class="w-full max-w-[190px]"
          />
        </div>
      </NuxtLink>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";
import { SERVICES } from "~/constants/categories-and-services";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  raw: {
    type: Object,
    required: true,
  },
});

const sectionBackgroundColor = useBackgroundColor(props);

const headerText = computed(() => props.content.headline);
const headerLink = computed(() => {
  if (
    !(Array.isArray(props.content.header_link) && props.content.header_link[0])
  )
    return false;
  return props.content.header_link[0].url;
});
const headerLinkText = computed(() => {
  if (
    !(Array.isArray(props.content.header_link) && props.content.header_link[0])
  )
    return false;
  return props.content.header_link[0].title;
});

function serviceBackgroundColor(service: any) {
  return useBackgroundColor({ content: service }).value;
}

function getServiceIllustration(service: any) {
  return SERVICES[service.plain_text_id]?.illustration;
}
</script>
