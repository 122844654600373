export function useHubspotForm({
  formId,
  elementSelector,
}: {
  formId: string;
  elementSelector: string;
}) {
  const HUBSPOT_SCRIPT_URL = "//js-eu1.hsforms.net/forms/embed/v2.js";

  onMounted(() => {
    const script = document.createElement("script");
    script.src = HUBSPOT_SCRIPT_URL;
    script.type = "text/javascript";
    script.async = true;

    script.onload = createForm;

    document.head.appendChild(script);
  });

  function createForm() {
    nextTick(() => {
      globalThis.hbspt.forms.create({
        region: "eu1",
        portalId: "25262360",
        formId,
        target: elementSelector,
      });
    });
  }
}
