<template>
  <BaseContent
    :style="`background-color: ${backgroundColor}`"
    class="bg-cover pt-20"
  >
    <div class="grid grid-cols-2 gap-8">
      <div class="col-span-2 md:col-span-1">
        <div class="flex h-full flex-col justify-center">
          <div class="mb-6 md:hidden">
            <img
              v-if="illustration"
              alt="service illustration"
              :src="illustration"
              fetchpriority="high"
              loading="eager"
              width="266"
              height="200"
            />
          </div>
          <h1 class="typo-h1 mb-4 text-grey-900" itemprop="name">
            {{ service.headline || service.title }}
          </h1>
          <p
            class="typo-body-xl mb-6 text-grey-900"
            itemprop="disambiguatingDescription"
          >
            {{ service.short_description }}
          </p>
          <BaseButtonV2 @click="startShowRequestFlow">{{
            $t("service_page.get_offers")
          }}</BaseButtonV2>
        </div>
      </div>
      <div class="hidden flex-col items-center justify-center md:flex">
        <img :src="illustration" />
      </div>
    </div>
  </BaseContent>
  <Teleport v-if="requestFlowIsShown" to="[data-teleport=request-flow]">
    <RequestFlow @close="hideRequestFlow" />
  </Teleport>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { SERVICES, CATEGORIES } from "~/constants/categories-and-services";
import { useUIStore } from "~/store/UIStore";
const { showRequestFlow, hideRequestFlow } = useUIStore();

const { requestFlowIsShown } = storeToRefs(useUIStore());

const isValid = computed(() => {
  return !!useRoute().meta.serviceSlug;
});

function startShowRequestFlow() {
  if (!isValid.value) {
    return;
  }

  showRequestFlow({
    serviceId: useRoute().meta.serviceSlug,
    posthog: { trigger: "get-offer-box", source: "service page header" },
  });
}
const props = defineProps<{
  service: Service;
  categoryId: string;
}>();

const backgroundColor = computed(() => {
  return CATEGORIES[props.categoryId as any]?.colors?.light || "#fff";
});

const illustration = computed(() => {
  return SERVICES[props.service.plain_text_id]?.illustration;
});
</script>
