<template>
  <div ref="wrapper" class="relative inline-block">
    <div class="flex h-full flex-col first:h-full" @click="toggleShowing">
      <slot name="trigger" />
    </div>
    <div
      v-if="showing"
      ref="popover"
      class="absolute z-50 min-w-0 overflow-visible rounded-lg border border-grey-200 bg-white p-3 shadow-alternative-soft-glow"
      :class="{
        'right-0': anchorRight,
        'bottom-full': anchorTop,
        '!p-0': noPadding,
        '!fixed left-0 top-0 !ml-0 !mt-0 !h-[100vh] !max-h-none !w-full':
          fullscreen,
      }"
      :style="`
      margin-left: ${offsetX};
      margin-top: ${offsetY};
      width: ${contentWidth};
      max-height:${contentMaxHeight};
      ${contentMaxHeight !== 'auto' ? 'overflow-y:auto;' : ''}
      `"
      @click="onHideOnClickInside"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import { BasePopoverProps } from "~/types/component-props";

const props = withDefaults(defineProps<BasePopoverProps>(), {
  disabled: false,
  noPadding: false,
  anchorRight: false,
  anchorTop: false,
  offsetX: "0",
  offsetY: "0",
  hideOnClickInside: false,
  contentWidth: "auto",
  contentMaxHeight: "auto",
  fullscreen: false,
});

const showing = ref(false);
const ignoreNextClickOutside = ref(false);
const wrapper = ref();
const popover = ref();

onClickOutside(wrapper, () => {
  hide();
});

const emit = defineEmits(["show", "hide", "interface"]);

watchEffect(() => {
  nextTick(() => {
    emit(showing.value ? "show" : "hide");
    if (showing.value) correctContentPosition();
  });
});

function onHideOnClickInside() {
  if (props.hideOnClickInside) {
    hide();
  }
}

function toggleShowing() {
  if (props.disabled) return;
  if (showing.value) {
    hide();
  } else {
    show();
  }
}

function show() {
  ignoreNextClickOutside.value = true;
  showing.value = true;
  emit("show");
  setTimeout(() => {
    ignoreNextClickOutside.value = false;
  }, 50);
}

function hide() {
  emit("hide");
  showing.value = false;
}

function correctContentPosition() {
  const position = popover.value.getBoundingClientRect();
  if (!props.anchorRight) {
    if (position.right > window.innerWidth) {
      popover.value.style.left = `-${position.right - window.innerWidth}px`;
    }
  } else if (position.left < 0) {
    popover.value.style.right = `${position.left}px`;
  }
  if (!props.anchorTop) {
    if (position.bottom > window.innerHeight) {
      popover.value.style.top = `${
        wrapper.value.clientHeight - (position.bottom - window.innerHeight)
      }px`;
    }
  } else if (position.top < 0) {
    popover.value.style.bottom = `${
      wrapper.value.clientHeight + position.top
    }px`;
  }
}

function exposeInterface() {
  emit("interface", {
    show: () => show(),
    hide: () => hide(),
  });
}

exposeInterface();
</script>
