<template>
  <div itemscope itemtype="https://schema.org/Offer">
    <SingleServiceHero
      v-if="categoryId"
      :service="service"
      :category-id="categoryId"
    />

    <div>
      <CmsBlockRenderer
        v-for="block in contentBlocks"
        :key="block.key"
        :block="block"
        :page-data="{ service, apiService: apiService?.data?.data }"
        :is-embedded="isEmbedded"
        :is-nested="false"
      />
    </div>

    <SingleServiceWhyOfficeguru v-if="!isEmbedded" />
    <template v-if="!REQUEST_FLOW_ACTIVATED">
      <MobileWrappedContactForm
        v-if="cmsValues?.link_customer_signup"
        class="block lg:hidden"
        :service="service"
        :create-account-link="cmsValues.link_customer_signup"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { track } from "~/utils/tracking/tracking";
import SingleServiceHero from "~/pages/components/single-service-hero.vue";
import SingleServiceWhyOfficeguru from "~/pages/components/single-service-why-officeguru.vue";
import MobileWrappedContactForm from "~/pages/components/mobile-wrapped-contact-form.vue";
import { useCMSStore } from "~/store/CMSStore";
import { useUIStore } from "~/store/UIStore";
import { getService } from "~/api/services";
import { getCmsServices } from "~/api/cms";

const { isEmbedded } = useUIStore();
const { t } = useI18n();
const $route = useRoute();
const { cmsValues } = useCMSStore();

const REQUEST_FLOW_ACTIVATED =
  cmsValues?.enable_request_flow || $route.query.rf;
const { data: serviceData }: any = await useAsyncData(
  $route.path as string,
  () => {
    return $fetch($route.meta.apiUrl as string);
  },
);
const service = computed<Service>(() => serviceData.value?.data);

useSeo(service.value?.seo);

onMounted(() => {
  track({
    event: "view_service",
    metadata: {
      service_id: service.value?.id,
      service_name: service.value?.title,
    },
    posthogEvent: {
      name: "View service",
      properties: {
        service_id: service.value?.id,
        service_name: service.value?.title,
      },
    },
  });
});

const apiService: Ref<any> = ref(null);

async function fetchService() {
  apiService.value = await getService(service.value.api_id);
}

const categoryId = computed(() => {
  return apiService.value?.data?.data?.category?.plain_text_id.toString();
});

fetchService();

const defaultSuggestedServices = ref([]);
async function getDefaultSuggestedServices() {
  // we fetch 8 random services and show 4 of them
  // The extra services are for the case that some of the services are hidden or deleted
  const data: any = await getCmsServices([
    "id",
    "title",
    "url",
    "excerpt",
    "api_url",
    "status",
  ]);
  defaultSuggestedServices.value = data.data
    .filter((s: any) => s.title && s.status === "published")
    .sort(() => (Math.random() > 0.5 ? 1 : -1))
    .slice(0, 4);
}
getDefaultSuggestedServices();

const contentBlocks = computed(() => {
  let blocks: any[] = [];
  if (service.value?.dynamic_content) {
    blocks = [...service.value.dynamic_content];
  }
  const hiddenDefaultBlocks = service.value.hide_default_content.flatMap(
    (block) => block.key,
  );
  if (!hiddenDefaultBlocks.includes("vendors")) {
    blocks.push({
      key: "default-vendors",
      type: "service_vendor_cards",
      vendors: [],
    });
  }
  if (!hiddenDefaultBlocks.includes("contact_form")) {
    blocks.push({
      key: "default-contact-form",
      type: "hubspot_prefilled_contact_form",
      content: {},
    });
  }
  if (!hiddenDefaultBlocks.includes("suggested_services")) {
    blocks.push({
      key: "default-suggested-services",
      type: "service_collection_text",
      services: defaultSuggestedServices.value,
      headline: t("service_page.others_also_bought"),
    });
  }
  return blocks;
});
</script>
