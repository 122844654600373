<template>
  <SafeNuxtLink
    :to="{ name: vendorLinkName(vendor.slug) }"
    class="overflow-hidden rounded-lg border border-grey-200"
  >
    <!-- Header -->
    <div
      class="relative flex h-[155px] items-center justify-center bg-grey-100 bg-cover bg-center"
      :style="
        getVendorImageUrl(vendor)
          ? `background-image: url('${getVendorImageUrl(vendor)}')`
          : ''
      "
    >
      <!-- Rating -->
      <div
        v-if="rating.count"
        class="absolute left-2 top-2 flex items-center gap-1 rounded-md bg-warm-neutral-200 px-1"
      >
        <span class="typo-body text-grey-900">{{
          Number(rating.score).toFixed(1)
        }}</span>
        <BaseIcon icon="star-full" :size="14"></BaseIcon>
        <span class="typo-body text-grey-500">({{ rating.count }})</span>
      </div>
      <!-- No background placeholder -->
      <div
        v-if="!getVendorImageUrl(vendor)"
        class="flex flex-col items-center gap-2"
      >
        <BaseIcon icon="picture" :size="14" class="text-grey-500" />
        <p class="typo-caption text-grey-400">Images coming soon!</p>
      </div>
    </div>
    <!-- Content -->
    <div class="p-4">
      <p class="typo-body-lg text-grey-900">{{ vendor.name }}</p>
      <div v-if="price || minPax" class="mt-1 flex w-full gap-2">
        <div
          v-if="price"
          class="typo-body flex items-center gap-1 text-grey-500"
        >
          <BaseIcon icon="discount" :size="14" />
          {{ $t("search.price") }}: {{ price.toLowerCase() }}
        </div>

        <span v-if="price && minPax" class="text-grey-500">•</span>

        <div
          v-if="minPax"
          class="typo-body flex items-center gap-1 text-grey-500"
        >
          <BaseIcon icon="group" :size="14" />
          {{ $t("search.min_count_pax", { count: minPax }) }}
        </div>
      </div>

      <VendorBadges
        v-if="vendor.badges"
        :badges="vendor.badges"
        class="mt-4 flex flex-wrap gap-1"
      />

      <div v-if="tags" class="mt-2.5 flex flex-wrap items-start gap-1">
        <BaseBadge
          v-for="tag in tags.slice(0, 5)"
          :key="tag"
          color="grey"
          :text="tag"
        ></BaseBadge>
      </div>
    </div>
  </SafeNuxtLink>
</template>

<script setup>
import { vendorLinkName } from "@/utils/linking.ts";
import { useCmsImage } from "~/utils/use-cms-image.ts";
const props = defineProps({
  vendor: {
    type: Object,
    required: true,
  },
});

const serviceMeta = computed(() => {
  if (typeof props.vendor?.services !== "object") return null;
  return Object.values(props.vendor?.services)?.[0];
});

const minPax = computed(() => {
  return serviceMeta.value?.content?.minimum_pax;
});

const price = computed(() => {
  return serviceMeta.value?.content?.price_range;
});

const tags = computed(() => {
  return serviceMeta.value?.tags;
});

const rating = computed(() => {
  return props.vendor?.rating;
});

function getVendorImageUrl(vendor) {
  let baseUrl;
  if (vendor.work_images.length) {
    baseUrl = vendor.work_images[0]?.url;
  } else if (vendor.images.logo) {
    baseUrl = vendor.images.logo;
  }
  if (!baseUrl) return null;
  const params = "width=500&height=160&fit=cover";
  return useCmsImage({
    src: baseUrl || "",
    params,
  }).value;
}
</script>
