import striptags from "striptags";

interface Lang {
  da: string;
  en: string;
  de: string;
  de_en: string;
}

interface Properties {
  title: string;
  description: string | null;
  locales: Lang;
  locales_absolute: Lang;
  canonical_url: string;
}

const seoProperties: Ref<Properties> = ref({
  title: "",
  description: "",
  locales: {
    da: "/da",
    en: "/en",
    de: "/de",
    de_en: "/de_en",
  },
  locales_absolute: {
    da: "/da",
    en: "/en",
    de: "/de",
    de_en: "/de_en",
  },
  canonical_url: "/",
});

function cleanAndTruncate(rawInput?: string | null, maxLength = 160) {
  if (!rawInput) {
    return "";
  }

  const cleanString = striptags(rawInput).trim();

  return cleanString.length > maxLength
    ? cleanString.substring(0, maxLength - 1) + "…"
    : cleanString;
}

const useSeo = (seo: SEO) => {
  seoProperties.value.title = seo.title;
  seoProperties.value.description = seo.description;
  seoProperties.value.locales.da = seo.locales.da || "/da";
  seoProperties.value.locales.en = seo.locales.en || "/en";
  seoProperties.value.locales.de = seo.locales.de || "/de";
  seoProperties.value.locales.de_en = seo.locales.de_en || "/de_en";
  seoProperties.value.locales_absolute.da = seo.locales_absolute.da || "/da";
  seoProperties.value.locales_absolute.en = seo.locales_absolute.en || "/en";
  seoProperties.value.locales_absolute.de = seo.locales_absolute.de || "/de";
  seoProperties.value.locales_absolute.de_en =
    seo.locales_absolute.de_en || "/de_en";
  seoProperties.value.canonical_url = seo.canonical_url;

  const hrefLangs: Array<{
    rel: string;
    hrefLang: string;
    href: any;
    key: string;
  }> = [];
  if (seo.locales_absolute) {
    Object.keys(seo.locales_absolute).forEach((locale: string) => {
      hrefLangs.push({
        rel: "alternate",
        hrefLang: locale,
        href: seo.locales_absolute[locale as keyof typeof seo.locales_absolute],
        key: locale,
      });
    });
  }

  const links: Array<{
    rel: string;
    href: string;
    key: string;
  }> = [];
  if (seo.canonical_url) {
    links.push({
      rel: "canonical",
      href: seo.canonical_url,
      key: "canonical",
    });
  }

  if (!seo.description) {
    useBugsnag().notify(
      new Error(
        `CMS page with an empty description detected. URL: ${seo.canonical_url}`,
      ),
      (event) => {
        event.addMetadata("additional", {
          seo,
          seoProperties: seoProperties.value,
          links,
          hrefLangs,
        });
      },
    );
  }

  useSeoMeta({
    title: cleanAndTruncate(seo.title, 60),
    description: cleanAndTruncate(seo.description),
  });

  const robots =
    seo.robots?.map((def) => {
      return def.key || def.value || def.label;
    }) || [];
  if (robots.length > 0) {
    useServerSeoMeta({
      robots: {
        noindex: robots.includes("noindex"),
        nofollow: robots.includes("nofollow"),
      },
    });
  }

  useHead({
    link: [...hrefLangs, ...links],
  });
};

export { useSeo, seoProperties };
