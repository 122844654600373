<template>
  <BaseContent :style="`${backgroundColor}`">
    <div>
      <ViewAllHeader
        :header-text="headerText"
        :header-link="headerLink || { name: 'discovery services' }"
        :header-link-text="headerLinkText || $t('all_services')"
      />
      <div>
        <div
          v-for="service in props.content.services"
          :key="service.id"
          class="border-b border-grey-200 py-5"
          itemscope
          itemtype="https://schema.org/Offer"
        >
          <NuxtLink
            :href="service.url"
            itemprop="url"
            class="flex flex-col truncate md:block"
            :title="service.excerpt"
          >
            <h5
              class="inline truncate text-grey-900 underline"
              :class="smallFont ? 'typo-body font-semibold' : 'typo-h5'"
              itemprop="name"
            >
              {{ service.title }}
            </h5>
            <span
              class="inline truncate text-grey-500 md:ml-2"
              :class="smallFont ? 'typo-body' : 'typo-body-xl'"
            >
              {{ service.excerpt }}
            </span>
          </NuxtLink>
        </div>
      </div>
    </div>
  </BaseContent>
</template>

<script lang="ts" setup>
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  raw: {
    type: Object,
    required: true,
  },
  smallFont: {
    type: Boolean,
    default: false,
  },
});

const backgroundColor = useBackgroundColor(props);

const headerText = computed(() => props.content.headline);
const headerLink = computed(() => {
  return props.content.header_link || "";
});
const headerLinkText = computed(() => {
  return props.content.header_link_text || "";
});
</script>
