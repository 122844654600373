import { enrichQuery, useFetch } from "#imports";
import { useAuthStore } from "~/store/AuthStore";
type UseFetchType = typeof useFetch;

const useFetchApi: UseFetchType = (request, opts) => {
  const lang = globalThis.lang === "de_en" ? "de" : globalThis.lang;
  let token: string | undefined = useAuthStore().token;
  // TODO: We need to get this fixed - this 'initial' value really sucks
  if (token === "initial") token = undefined;

  if (opts?.baseURL) {
    throw new Error("Can not override baseURL in useFetchApi");
  }

  const mergedOpts = opts || {};
  mergedOpts.baseURL = useRuntimeConfig().public.apiUrl;
  mergedOpts.headers = {
    Accept: "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...opts?.headers,
  };
  mergedOpts.query = opts?.query ? { ...opts.query, lang } : { lang };

  return useFetch(request, {
    ...mergedOpts,
    // this has been added to make server requests work the same way as client requests work,
    // check 02.ofetch.ts for more details
    onRequest: ({ request, options }) => {
      enrichQuery({ request, options });
    },
  });
};

export { useFetchApi };
