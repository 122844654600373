import { useFetchApi } from "~/utils/use-fetch-api";

function getDiscoveryProducts(params: URLSearchParams) {
  return useFetchApi(`public/discovery/products?${params.toString()}`);
}

function getDiscoveryServices(params: URLSearchParams) {
  return useFetchApi(`public/discovery/services?${params.toString()}`);
}

function getDiscoveryPartners(params: URLSearchParams) {
  return useFetchApi(`public/discovery/partners?${params.toString()}`);
}

export { getDiscoveryProducts, getDiscoveryServices, getDiscoveryPartners };
