<template>
  <ClientOnly>
    <component :is="isNested ? 'div' : BaseContent" :style="backgroundStyle">
      <div class="flex flex-col" :class="horizontalAlignment">
        <div
          class="w-full rounded-lg bg-white p-8 pb-5 shadow-alternative-soft-glow md:max-w-[467px]"
        >
          <h2 v-if="headline" class="typo-h5 mb-6">{{ headline }}</h2>
          <div :id="containerId" :class="[verticalPadding]" />
        </div>
      </div>
    </component>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useVerticalPadding } from "~/components/cms-blocks/components/use-vertical-padding";
import { useBackgroundColor } from "~/components/cms-blocks/components/use-background-color";
import { useBackgroundImage } from "~/components/cms-blocks/components/use-background-image";
import { randomId } from "~/utils/random-id";
import { BaseContent } from "#components";
import "~/assets/css/hubspot-form.css";
import { useHorizontalAlignment } from "~/components/cms-blocks/components/use-horizontal-alignment";
import { useHubspotForm } from "~/utils/use-hubspot-form";
const containerId = randomId();

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
  isNested: {
    type: Boolean,
    default: false,
  },
});

const formId = computed(() => {
  return props.content.form_id;
});

const headline = computed(() => {
  return props.content.headline;
});

const horizontalAlignment = useHorizontalAlignment(props);
const verticalPadding = useVerticalPadding(props);
const backgroundColor = useBackgroundColor(props);
const backgroundImage = useBackgroundImage(props);
const backgroundStyle = backgroundImage.value
  ? backgroundImage.value
  : backgroundColor.value;

useHubspotForm({
  formId: formId.value,
  elementSelector: `#${containerId}`,
});
</script>
