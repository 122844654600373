<template>
  <input
    class="typo-body w-full rounded-lg border border-grey-300 p-2.5 outline-none placeholder:text-grey-500 focus:border-grey-400"
    :class="{
      [inputClasses]: true,
      'border-semantic-negative-700 bg-semantic-negative-50':
        hasError || forceError,
    }"
    :type="password ? 'password' : 'text'"
    :value="value"
    :placeholder="placeholder"
    :autocomplete="password ? 'new-password' : ''"
    @input="emit('update:value', ($event.target as HTMLInputElement).value)"
  />
  <FormsComponentsFieldError
    :errors="errors"
    :field-name="fieldName"
    :error-name-space="errorNameSpace"
  />
</template>

<script setup lang="ts">
import { useHasFieldError } from "~/utils/use-has-field-error";

const emit = defineEmits(["update:value"]);
const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: null,
  },
  fieldName: {
    type: String,
    required: true,
  },
  errorNameSpace: {
    type: String,
    required: true,
  },
  password: {
    type: Boolean,
    default: false,
  },
  // force the error state of the field (border, background) without having an actual error
  // this can be used when the component is too inflexible and errors are displayed outside of it,
  // but the styling is needed
  forceError: {
    type: Boolean,
    default: false,
  },
  inputClasses: {
    type: String,
    default: "",
  },
});

const hasError = useHasFieldError(props);
</script>
