export default function vendorProfilePath(slug: string) {
  if (!slug) {
    throw new Error("slug must be defined");
  }

  function getBasePath() {
    if (globalThis.lang === "da") {
      return `/${globalThis.lang}/partnere`;
    }
    if (globalThis.lang === "en") {
      return `/${globalThis.lang}/vendors`;
    }
    if (globalThis.lang === "de") {
      return `/${globalThis.lang}/partner`;
    }
    if (globalThis.lang === "de_en") {
      return `/${globalThis.lang}/vendors`;
    }
    throw new Error(
      "globalThis.lang holds unknown language: " + globalThis.lang,
    );
  }

  return `${getBasePath()}/${slug}`;
}
