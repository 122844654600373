<template>
  <nav aria-label="Language switcher" class="relative">
    <button
      class="flex items-center justify-center gap-2 p-4 pr-0 !text-grey-900"
      @click="languageDropdownShowing = !languageDropdownShowing"
    >
      <BaseIcon icon="globe" :size="14" />
      {{ selectedLang.label }}
      <BaseIcon
        icon="caret-down"
        :size="14"
        class="text-grey-500"
        :class="{ 'rotate-180': languageDropdownShowing }"
      />
    </button>
    <div
      v-if="languageDropdownShowing"
      class="absolute bottom-full right-0 flex flex-col gap-2 rounded-lg bg-white p-2.5 shadow-soft-glow"
    >
      <a
        v-for="(lang, index) in languages"
        :key="index"
        :href="`${lang.link}`"
        class="flex items-center gap-2 rounded-lg px-6 py-2 text-grey-900 transition-all hover:bg-grey-100"
      >
        <img
          :src="lang.flag"
          class="min-w-[20px] max-w-[20px]"
          alt=""
          loading="lazy"
        />
        {{ lang.label }}
      </a>
    </div>
  </nav>
</template>

<script setup lang="ts">
import flagDK from "assets/flag_dk.svg?url";
import flagUK from "assets/flag_uk.svg?url";
// import flagDE from "assets/flag_de.svg?url";
import { seoProperties } from "~/utils/use-seo";

const languageDropdownShowing = ref(false);

const { locales } = seoProperties.value;

const languages = computed(() => [
  {
    label: "Dansk",
    link: locales.da,
    key: "da",
    flag: flagDK,
  },
  {
    label: "English",
    link: locales.en,
    key: "en",
    flag: flagUK,
  },
  // TODO: Too early! ;) Re-enable once we go live
  // {
  //   label: "Deutsch",
  //   link: locales.de,
  //   key: "de",
  //   flag: flagDE,
  // },
]);

const selectedLang = computed(() => {
  return (
    languages.value.find((lang) => lang.key === globalThis.lang) ||
    languages.value[0]
  );
});

function closeLanguageDropdown(e: Event) {
  if (
    !(e.target as HTMLElement).closest("[aria-label='Language switcher']") &&
    languageDropdownShowing.value
  ) {
    languageDropdownShowing.value = false;
  }
}

onMounted(() => {
  window.addEventListener("click", closeLanguageDropdown);
});

onBeforeUnmount(() => {
  window.removeEventListener("click", closeLanguageDropdown);
});
</script>
