<template>
  <template v-if="options.length > 0">
    <SelectDropdown
      :model-value="modelValue"
      :options="options"
      trigger-element-classes="rounded-lg !h-[44px] border-grey-300 px-2.5 cursor-pointer"
      content-width="100%"
      @update:model-value="emit('update:modelValue', $event)"
    />
    <FormsComponentsFieldError
      :errors="errors"
      :field-name="fieldName"
      :error-name-space="errorNameSpace"
    />
  </template>
  <template v-else>
    <span
      class="typo-body flex h-[44px] items-center rounded-lg border border-grey-300 px-2.5 text-grey-400"
    >
      <BaseIcon icon="loading" class="animate-spin" :size="14" />
      <span class="ml-1.5">{{ $t("shared.loading_countries") }}</span>
    </span>
  </template>
</template>

<script setup lang="ts">
import { getCountries } from "~/api/countries";

interface Country {
  id: string;
  name: string;
  iso2: string;
}

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: null,
  },
  fieldName: {
    type: String,
    required: true,
  },
  errorNameSpace: {
    type: String,
    required: true,
  },
  password: {
    type: Boolean,
    default: false,
  },
  // force the error state of the field (border, background) without having an actual error
  // this can be used when the component is too inflexible and errors are displayed outside of it,
  // but the styling is needed
  forceError: {
    type: Boolean,
    default: false,
  },
  inputClasses: {
    type: String,
    default: "",
  },
});

const options = ref<{ label: string; value: string }[]>([]);

const { data: countriesRaw } = await useAsyncData("countries", async () => {
  try {
    const response = (await getCountries()) as {
      data?: { value: { data: Country[] } };
      error?: unknown;
    };
    if (!response.data?.value) return Promise.reject(response.error);
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
});

const countries = computed<Country[]>(() => {
  const value = countriesRaw.value as
    | { data: Country[] }
    | { value: { data: Country[] } }
    | undefined;
  if (value && "data" in value && Array.isArray(value.data)) {
    return value.data;
  } else if (value && "value" in value && Array.isArray(value.value.data)) {
    return value.value.data;
  } else {
    return [];
  }
});

options.value = countries.value.map((c: Country) => ({
  label: c.name,
  value: c.id,
}));

onMounted(() => {
  const langIsoMap = {
    da: "DK",
    en: "DK",
    de: "DE",
    de_en: "DE",
  };

  if (props.modelValue === "") {
    const defaultId = countries.value.find(
      (c: any) =>
        c.iso2 === langIsoMap[globalThis.lang as keyof typeof langIsoMap],
    )?.id;
    const defaultOption = options.value.find(
      (o) => (o as any).value === defaultId,
    );

    if (defaultOption) {
      emit("update:modelValue", (defaultOption as any).value);
    } else {
      emit("update:modelValue", (options.value[0] as any).value);
    }
  }
});
</script>
