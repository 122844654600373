import { setDefaultOptions } from "date-fns";
import { Router } from "vue-router";

import da from "date-fns/locale/da/index";
import enGB from "date-fns/locale/en-GB/index";
import de from "date-fns/locale/de/index";

const dateLocales: any = {
  da,
  en: enGB,
  de,
  de_en: de,
};

export default defineNuxtPlugin((nuxtApp) => {
  const router = nuxtApp.$router as Router;
  const path = router.currentRoute.value?.path || "";
  const lang = path.match(/\/([a-z]{2}(?:_[a-z]{2})?)(?:\/|$)/)?.[1] || "";
  const supportedLangs = ["da", "en", "de", "de_en"];

  globalThis.lang = !supportedLangs.includes(lang) ? "da" : lang;
  // set date-fns locale
  setDefaultOptions({ locale: dateLocales[globalThis.lang] });
  useHead({
    htmlAttrs: {
      lang: globalThis.lang,
    },
  });
});
