const supportedCurrencies = ["DKK", "EUR"];
let currency = null;

function useCurrency() {
  function setCurrency({ currency: c }) {
    if (!supportedCurrencies.includes(c)) {
      throw new Error("Unsupported currency");
    }
    currency = c;
  }

  function formatCurrency(value) {
    if (!currency) {
      throw new Error(
        "Currency not set, use `setCurrency` first to set currency",
      );
    }

    const maybeNumber = castToNumber(value);

    if (Number.isNaN(maybeNumber)) return "";

    const number = maybeNumber;

    if (currency === "DKK") {
      return formatDKK(number);
    }

    if (currency === "EUR") {
      return formatEUR(number);
    }
  }

  function formatEUR(number) {
    return number.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  }

  function formatDKK(number) {
    return number.toLocaleString("da-DK", {
      style: "currency",
      currency: "DKK",
      currencyDisplay: "code",
    });
  }

  function getCurrencySymbol() {
    if (!currency) {
      throw new Error(
        "Currency not set, use `setCurrency` first to set currency",
      );
    }

    if (currency === "DKK") return formatDKK(0).split(" ")[1];
    if (currency === "EUR") return formatEUR(0).split(" ")[1];
  }

  function castToNumber(value) {
    return Number(value);
  }

  return {
    setCurrency,
    formatCurrency,
    getCurrencySymbol,
  };
}

export { useCurrency };
