<template>
  <div
    ref="componentRoot"
    class="group/wrapper flex"
    :class="{ compact: compact }"
    role="search"
  >
    <div
      class="group relative flex items-center rounded-md border-solid border-grey-300 bg-transparent text-body text-grey-900 transition-all focus-within:!border-grey-500 hover:border-grey-500 group-[.compact]/wrapper:border"
    >
      <div
        class="flex w-auto items-center rounded-md pl-3 text-body-lg after:text-grey-200 group-[.compact]/wrapper:bg-transparent group-[.compact]/wrapper:text-body md:shrink-0"
        :class="{ 'bg-white/75': !compact }"
      >
        <GlobalSearchPopover
          :results="searchResults"
          :meta="searchMeta"
          :search-term="searchTerm"
          :loading="searchLoading"
          :fullscreen="mobileView"
          @open-results="openSearchResults"
          @interface="searchMenuInterface = $event"
        >
          <!-- mobile input -->
          <div v-if="mobileView" class="flex items-center gap-4 bg-grey-50 p-4">
            <BaseIcon
              icon="close"
              class="cursor-pointer"
              :size="14"
              @click="closeSearch"
            />
            <input
              ref="mobileInput"
              v-model="searchTerm"
              required
              :placeholder="$t('search.find_anything')"
              class="h-12 w-full grow rounded-lg border border-transparent bg-white pl-2.5 outline-none placeholder:text-grey-500 focus:border-grey-500 group-[.compact]/wrapper:h-8"
              aria-label="Search field"
              @input="search"
              @click="showIfResultsExist"
              @keydown.enter="() => openSearchResults()"
            />
          </div>
        </GlobalSearchPopover>
        <input
          ref="desktopInput"
          v-model="searchTerm"
          required
          :placeholder="$t('search.find_anything')"
          class="h-12 w-full grow truncate bg-transparent text-grey-900 outline-none placeholder:text-grey-500 group-[.compact]/wrapper:h-8"
          :class="{ 'md:min-w-[190px]': !compact }"
          aria-label="Search field"
          @input="search"
          @click="showIfResultsExist"
          @keydown.enter="() => openSearchResults()"
        />
        <button
          v-if="compact"
          class="flex h-[32px] w-[48px] items-center justify-center rounded-r-md transition-all duration-200"
          :class="{
            'bg-grey-900 text-white': searchTerm,
            'text-coral-500': !searchTerm,
          }"
        >
          <BaseIcon icon="search" :size="14" />
        </button>
      </div>
      <BaseButtonV2 v-if="!compact" class="ml-2" @click="openSearchResults">
        {{ $t("search.search") }}
      </BaseButtonV2>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";
import GlobalSearchPopover from "~/components/global-search-popover.vue";
import { toast } from "~/utils/toast";
import { useBugsnag } from "#imports";

defineProps({
  compact: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const searchLoading = ref(false);
const searchResults = ref<null | OgApi.Search.ResultsSlim>(null);
const searchMeta = ref<null | {
  totals: { services: number; products: number; partners: number };
}>(null);
const searchTerm = ref("");
const componentRoot = ref();
const searchMenuInterface = ref<null | { show: any; hide: any }>(null);
const cancelDebounce = ref(false);
const route = useRoute();
const router = useRouter();

watch(
  route,
  () => {
    searchTerm.value = (route.query.search as string) || "";
    searchMenuInterface.value?.hide();
    searchResults.value = null;
  },
  {
    immediate: true,
    deep: true,
  },
);

const search = useDebounceFn(() => {
  if (cancelDebounce.value) return;
  if (searchTerm.value && searchTerm.value.length > 1) {
    searchLoading.value = true;
    searchMenuInterface.value?.show();
    useFetchApi("/public/search", {
      params: {
        search: searchTerm.value,
        per_page: 5,
        resource: "slim",
      },
    })
      .then((response: any) => {
        searchResults.value = response.data.value.data;
        searchMeta.value = response.data.value.meta;
        if (
          response.data.value.meta.totals.products ||
          response.data.value.meta.totals.partners ||
          response.data.value.meta.totals.services
        ) {
          usePosthog().capture("Search | Results found", {
            url: window.location.href,
            search_term: searchTerm.value,
            results: response.data.value.meta.totals,
          });
        } else {
          usePosthog().capture("Search | No results found", {
            url: window.location.href,
            search_term: searchTerm.value,
          });
        }
      })
      .catch((error) => {
        toast({
          text: t("search.something_went_wrong"),
          type: "error",
          duration: 5000,
          id: "global-search",
        });

        useBugsnag().notify(
          new Error(`Something went wrong while using global search`),
          (event) => {
            event.addMetadata("additional", {
              error,
              searchTerm: searchTerm.value,
            });
          },
        );
      })
      .finally(() => {
        searchLoading.value = false;
      });
  } else {
    searchResults.value = null;
    if (!mobileView.value) {
      searchMenuInterface.value?.hide();
    }
    searchLoading.value = false;
  }
}, 300);

function showIfResultsExist() {
  if (searchResults.value || mobileView.value) {
    searchMenuInterface.value?.show();
    nextTick(() => {
      if (mobileView.value) {
        mobileInput.value?.focus();
      }
    });
  }
}

function onWindowResize() {
  mobileView.value = window.innerWidth < 575;
}

const desktopInput = ref();
const mobileInput = ref();
const mobileView = ref(false);

function closeSearch() {
  if (mobileInput.value) {
    mobileInput.value.blur();
  }
  if (desktopInput.value) {
    desktopInput.value.blur();
  }
  searchMenuInterface.value?.hide();
  cancelDebounce.value = true;
  setTimeout(() => {
    cancelDebounce.value = false;
  }, 600);
}

function determineDiscoveryRoute() {
  if (
    ["discovery services", "discovery vendors", "discovery products"].includes(
      route.name as string,
    )
  ) {
    return route.name as string;
  }
  return "discovery";
}

function openSearchResults(tab?: string) {
  closeSearch();
  const existingQuery =
    determineDiscoveryRoute() !== "discovery" ? route.query : {};
  router.push({
    name: tab || determineDiscoveryRoute(),
    query: {
      ...existingQuery,
      search: searchTerm.value,
    },
  });
}

onMounted(() => {
  // hide menu when resizing so that we don't accidentally keep it open while entering the larger breakpoints
  // where it shouldn't exist
  globalThis.addEventListener("resize", onWindowResize);
  onWindowResize();
});

onUnmounted(() => {
  globalThis.removeEventListener("resize", onWindowResize);
});
</script>
