<template>
  <BaseContent>
    <div class="mb-6 flex min-w-0 items-center justify-between">
      <h2 class="typo-h2 truncate text-grey-900">
        {{
          content.title ||
          $t("service_page.service_x_vendors", { service: raw?.service?.title })
        }}
      </h2>

      <nuxt-link
        :to="{
          name: 'discovery vendors',
          query: {
            service_id: raw?.apiService?.route_key,
          },
        }"
      >
        <BaseButtonV2 class="text-grey-900" type="link">{{
          $t("service_page.discover_all_vendors")
        }}</BaseButtonV2>
      </nuxt-link>
    </div>
    <div class="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <SingleDiscoveryVendorCard
        v-for="vendor in servicePartners"
        :key="vendor.id"
        class="col-span-1"
        :vendor="vendor"
      />
    </div>
    <div class="flex justify-center">
      <nuxt-link
        :to="{
          name: 'discovery vendors',
          query: {
            service_id: raw?.apiService?.route_key,
          },
        }"
      >
        <BaseButton>{{ $t("service_page.discover_all_vendors") }}</BaseButton>
      </nuxt-link>
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import SingleDiscoveryVendorCard from "~/pages/components/discovery/single-discovery-vendor-card.vue";
import { getDiscoveryPartners } from "~/api/discovery";

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  raw: {
    type: Object,
    required: true,
  },
});

const servicePartnersRaw: Ref<any> = ref(null);
async function fetchServicePartners() {
  const params = new URLSearchParams();
  if (props.raw?.service?.api_id) {
    params.append("service_id", props.raw.service.api_id);
  }
  if (props.content.vendors.length) {
    props.content.vendors.forEach((vendor: Vendor) => {
      params.append("partner_id[]", vendor.api_id);
    });
  } else {
    params.append("per_page", "6");
  }
  params.append("order_by", "score");
  params.append("order", "desc");
  servicePartnersRaw.value = await getDiscoveryPartners(params);
}

fetchServicePartners();

const servicePartners = computed(() => {
  return servicePartnersRaw.value?.data?.data || [];
});
</script>
