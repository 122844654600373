import { useRuntimeConfig } from "#imports";

function getCmsServices(fields?: string[]): Promise<CmsCollectionProp[]> {
  const config = useRuntimeConfig();
  const params = new URLSearchParams();
  params.append(
    "filter[site]",
    globalThis.lang === "de_en" ? "de" : globalThis.lang,
  );
  if (fields) {
    params.append("fields", fields.join(","));
  }
  params.append("limit", "9999");
  const url = `${
    config.public.cmsUrl
  }api/collections/services/entries?${params.toString()}`;
  return $fetch(url);
}

export { getCmsServices };
