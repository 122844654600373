<template>
  <ClientOnly>
    <BaseContent class="bg-semantic-notice-100">
      <div
        class="flex flex-1 flex-col justify-between gap-8 text-center lg:flex-row lg:items-center lg:gap-20"
      >
        <div class="flex flex-col items-start">
          <h2 class="typo-h2 mb-5 text-grey-900">{{ title }}</h2>
          <p class="typo-body-xl text-left text-grey-700">
            {{ text }}
          </p>
        </div>
        <div
          class="flex w-full flex-col rounded-lg bg-white p-8 pb-5 text-left shadow-alternative-soft-glow md:max-w-[467px]"
        >
          <h2 class="typo-h4 mb-6 font-medium text-grey-900">
            {{ formHeadline }}
          </h2>
          <div :id="`contact-form-${randomId}`"></div>
        </div>
      </div>
    </BaseContent>
  </ClientOnly>
</template>

<script setup lang="ts">
import "~/assets/css/hubspot-form.css";
import { useI18n } from "vue-i18n";
import { useHubspotForm } from "~/utils/use-hubspot-form";
const { t } = useI18n();
const randomId = Math.random().toString(36).substring(7);

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
});

const title = computed(() => {
  return props.content.title || t("service_page.questions");
});

const text = computed(() => {
  return props.content.text || t("service_page.not_ready_for_an_offer_yet");
});

const formHeadline = computed(() => {
  return props.content.form_headline || t("service_page.contact_form");
});

const formId = computed(() => {
  return props.content.form_id || "0c505f52-160a-4c4a-a7f7-1b5bda8382a2";
});

useHubspotForm({
  formId: formId.value,
  elementSelector: `#contact-form-${randomId}`,
});
</script>
