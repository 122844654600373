import { Ref } from "vue";
import { useRuntimeConfig, useCurrency } from "#imports";

interface CmsValues {
  link_terms_of_payment?: string;
  link_customer_signup?: string;
  link_marketplace_page?: string;
  link_all_services_page?: string;
  link_how_it_works?: string;
  enable_request_flow: boolean;
  blog_sidebar_sendgrid_form_url?: string;
  blog_sidebar_sendgrid_form_height?: number;
  footer_columns?: Array<{
    headline: string;
    links: Array<{ link: string; link_text: string }>;
  }>;
  currency: {
    value: "DKK" | "EUR";
    key: "DKK" | "EUR";
    label: "DKK" | "EUR";
  };
}

export const useCMSStore = defineStore("CMS", () => {
  const cmsValues: Ref<CmsValues | null> = ref(null);
  const { setCurrency } = useCurrency();

  async function populate() {
    const $config = useRuntimeConfig();

    const { data } = await useAsyncData("cmsGlobals", () =>
      globalThis.$fetch(
        `${$config.public.cmsUrl}api/globals/frontend_value_sync`,
      ),
    );

    const values = ref((data.value as any)?.data || {});

    cmsValues.value = values.value;

    setCurrency({ currency: values.value.currency?.value });

    const requiredLinks = [
      "link_terms_of_payment",
      "link_customer_signup",
      "link_marketplace_page",
      "link_all_services_page",
      "link_how_it_works",
    ];

    if (!requiredLinks.every((link) => values.value[link])) {
      useBugsnag().notify(
        new Error(`Missing required links from cms global values`),
        (event) => {
          event.addMetadata("additional", {
            providedLinks: requiredLinks.reduce((acc, cur) => {
              return {
                ...acc,
                [cur]: values.value[cur], // Fix: correctly accessing values
              };
            }, {}),
          });
        },
      );
    }
  }

  return {
    populate,
    cmsValues,
  };
});
